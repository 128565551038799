import http from "@/http";
import router from "@/router";

export default {
  login(objLogin) {
    return new Promise(function(resolve, reject) {
      http
        .post("user/login", objLogin)
        .then((response) => {
          if (response.data.authenticated) {
            localStorage.user = JSON.stringify(response.data);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("retryL");
    router.push("/login");
  },
  isLoggedIn() {
    if (localStorage.user === undefined) return false;
    let user = JSON.parse(localStorage.user);
    if (user.token == "") return false;
    if (new Date() > new Date(user.expiration)) return false;

    return true;
  },
  getUser() {
    if (localStorage.user != undefined && localStorage.user != "") {
      return JSON.parse(localStorage.user);
    } else {
      return false;
    }
  },
  getHeaders(contentType) {
    return {
      headers: {
        Authorization: "Bearer " + this.getUser().accessToken,
        "Content-Type": contentType ? contentType : "application/json",
      },
    };
  },
};
