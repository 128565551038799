import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { VueMasonryPlugin } from "vue-masonry";
Vue.use(VueMasonryPlugin);

import VueCurrencyFilter from "vue-currency-filter";
Vue.use(VueCurrencyFilter, {
  symbol: "R$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});

import "./assets/css/desktop.css";
import "./assets/css/tablet.css";
import "./assets/css/mobile.css";
import "./assets/css/animate.css";

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

// import vSelect from "vue-select";
// Vue.component("v-select", vSelect);

import VCalendar from 'v-calendar';
Vue.use(VCalendar);

import VueTranslate from "vue-translate-plugin";
Vue.use(VueTranslate);

import moment from "moment";
require("moment/locale/pt-br");
import VueMoment from "vue-moment";
Vue.use(VueMoment, { moment });
import VueSweetalert2 from "vue-sweetalert2";
const options = {
  confirmButtonColor: "#0f2651",
  cancelButtonColor: "#aaa",
};
Vue.use(VueSweetalert2, options);
import "sweetalert2/dist/sweetalert2.min.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faChevronLeft,
  faSearch,
  faBars,
  faHome,
  faCamera,
  faClock,
  faIdBadge,
  faUser,
  faLightbulb,
  faSignOutAlt,
  faEnvelope,
  faFile,
  faFilter,
  faKey,
  faIdCardAlt,
  faCreditCard,
  faBarcode,
  faCloudDownloadAlt,
  faCopy,
  faCheck,
  faTimes,
  faChevronCircleRight,
  faChevronCircleLeft,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faChevronLeft,
  faChevronRight,
  faFacebook,
  faInstagram,
  faYoutube,
  faSearch,
  faBars,
  faHome,
  faCamera,
  faClock,
  faIdBadge,
  faUser,
  faLightbulb,
  faSignOutAlt,
  faEnvelope,
  faFile,
  faFilter,
  faKey,
  faIdCardAlt,
  faCreditCard,
  faBarcode,
  faCloudDownloadAlt,
  faCopy,
  faCheck,
  faTimes,
  faChevronCircleRight,
  faChevronCircleLeft,
  faCalendarAlt
);

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
