<template>
  <header>
    <div class="container">
      <img
        src="../assets/images/logo.png"
        alt="My Social Media Logo"
        class="logo desktop-hidden"
      />

      <div
        v-show="!isContentProgramming"
        @click="showFilter = true"
        class="btn filter desktop-hidden"
      >
        <font-awesome-icon :icon="['fas', 'filter']" />
        <span>{{ t("Filtros") }}</span>
      </div>
      <transition
        enter-active-class="animated fadeInDown"
        leave-active-class="animated fadeOutUp"
      >
        <div v-show="showFilter && !isContentProgramming" class="filters">
          <div class="input-item">
            <span>{{ t("Tipo") }}:</span>

            <select @change="filterCalendar" v-model="selectedType">
              <option value="">
                {{ t("Todos") }}
              </option>
              <option value="feed">Feed</option>
              <option value="stories">Stories</option>
              <option value="reels">Reels</option>
            </select>
          </div>
          <div class="input-item">
            <span>{{ t("Formato") }}:</span>

            <select @change="filterCalendar" v-model="selectedFormat">
              <option value="">
                {{ t("Todos") }}
              </option>
              <option value="images">{{ t("Imagem") }}</option>
              <option value="videos">{{ t("Vídeo") }}</option>
              <option value="carousel">{{ t("Carrossel") }}</option>
            </select>
          </div>
          <div class="input-item">
            <span>{{ t("Paleta de cores") }}:</span>
            <select
              @change="changeStyle"
              name="paleta de cores"
              v-model="selectedStyle"
            >
              <option v-for="style in styles" :key="style.id" :value="style">
                {{ style.name }}
              </option>
            </select>
            <div class="palette-colors">
              <span
                v-for="(color, index) in selectedStyle.colors"
                :key="`color-palette-item${index}`"
                class="color-palette-item"
                :style="`--i: ${index}; background-color:${color}`"
              ></span>
            </div>
          </div>
          <div class="input-item">
            <span>{{ t("Tema") }}:</span>
            <select
              @change="filterCalendar"
              name="temas"
              v-model="selectedTheme"
            >
              <option value="">
                {{ t("Todos") }}
              </option>
              <option v-for="theme in themes" :key="theme.id" :value="theme.id">
                {{ theme.name }}
              </option>
            </select>
          </div>
          <div class="date-input-holder">
            <span>{{ t("Data") }}</span>
            <v-date-picker
              locale="pt-br"
              :popover="{ visibility: 'click' }"
              :first-day-of-week="1"
              :attributes="datePickerAttributes"
              v-model="date"
            >
              <template #default="{ inputValue, inputEvents }">
                <input
                  class="px-3 py-1 border rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                  :disabled="loadingCalendar"
                  style="cursor: pointer"
                />
                <div v-show="loadingCalendar" class="loading2"></div>
              </template>
            </v-date-picker>
          </div>

          <div @click="emitFilter" class="btn mt-35">
            <span>Filtrar</span>
          </div>
        </div>
      </transition>

      <transition
        enter-active-class="animated fadeInDown"
        leave-active-class="animated fadeOutUp"
      >
        <div v-show="isContentProgramming" class="date-holder">
          <font-awesome-icon
            @click="changeFilterMonth('left')"
            :icon="['fas', 'chevron-left']"
          />
          <span class="date"
            >{{ dateContentProgramming | moment("MMMM YYYY") }}
          </span>
          <font-awesome-icon
            @click="changeFilterMonth('next')"
            :icon="['fas', 'chevron-right']"
          />
        </div>
      </transition>

      <div @click.stop="showMenu = !showMenu" class="menu-holder">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </div>

      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <nav
          data-html2canvas-ignore
          v-show="showMenu"
          v-click-outside="hideMenu"
        >
          <ul>
            <LanguageComp />
            <li class="desktop-hidden">
              <router-link
                to="/"
                class="create-account"
                :class="{ active: $route.name == 'About' }"
              >
                <font-awesome-icon :icon="['fas', 'home']" />
                <p>{{ t("Início") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link
                to="/programacao-conteudo"
                class="create-account"
                :class="{ active: $route.name == 'About' }"
              >
                <font-awesome-icon :icon="['fas', 'calendar-alt']" />
                <p>{{ t("Programação de conteúdo") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link
                to="/apoio-extra"
                class="create-account"
                :class="{ active: $route.name == 'About' }"
              >
                <font-awesome-icon :icon="['fas', 'lightbulb']" />
                <p>{{ t("Apoio extra") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link
                to="/meu-selo"
                class="create-account"
                :class="{ active: $route.name == 'About' }"
              >
                <font-awesome-icon :icon="['fas', 'camera']" />
                <p>{{ t("Meu selo") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link
                to="/meus-dados"
                class="create-account"
                :class="{ active: $route.name == 'About' }"
              >
                <font-awesome-icon :icon="['fas', 'user']" />
                <p>{{ t("Meus dados") }}</p>
              </router-link>
            </li>
            <li class="desktop-hidden">
              <router-link
                :to="userPlanLink"
                class="create-account"
                :class="{ active: $route.name == 'About' }"
              >
                <font-awesome-icon :icon="['fas', 'id-badge']" />
                <p>{{ t("Meu plano") }}</p>
              </router-link>
            </li>
            <li>
              <a
                href="https://mysm.com.br/docs/termos-my-social-media.pdf"
                target="_blank"
                class="create-account"
                :class="{ active: $route.name == 'Terms' }"
              >
                <font-awesome-icon :icon="['fas', 'file']" />
                <p>{{ t("Termos de Uso") }}</p>
              </a>
            </li>
            <li>
              <a @click="logout" style="cursor: pointer">
                <font-awesome-icon
                  :icon="['fas', 'sign-out-alt']"
                  style="transform: scaleX(-1)"
                />
                <p>{{ t("Sair") }}</p>
              </a>
            </li>
          </ul>
        </nav>
      </transition>
    </div>
  </header>
</template>

<script>
import http from "@/http";
import authentication from "../util/authentication";
import LanguageComp from "@/components/LanguageComp";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    LanguageComp,
  },
  props: {
    isContentProgramming: {
      type: Boolean,
      default: false,
    },
    dateFilterContentProgramming: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      opened: false,
      selectedType: "",
      selectedFormat: "",
      selectedStyle: {
        colors: [],
      },
      date: this.$moment(Date()).format("YYYY-MM-DD"),
      showMenu: false,
      styles: [],
      themes: [],
      selectedTheme: "",
      showFilter: true,
      dateContentProgramming: {},
      datePickerAttributes: [
        {
          dot: {
            style: {
              backgroundColor: "var(--primary)",
            },
          },
          dates: [],
        },
      ],
      loadingCalendar: true,
    };
  },
  computed: {
    maxDateAllowed() {
      return new Date().toISOString().substring(0, 10);
    },
    isAppleUser() {
      return authentication.getUser().info.userType == "Apple";
    },
    userPlanLink() {
      return this.isAppleUser ? "assinatura/apple" : "meu-plano";
    },
    formattedDate() {
      console.log(this.$moment(this.date).format("YYYY-MM-DD"));
      return this.$moment(this.date).format("YYYY-MM-DD");
    },
  },
  created() {
    this.getStyles();
    this.getThemes();
    this.filterCalendar();
  },
  mounted() {
    if (window.innerWidth < 1140) {
      this.showFilter = false;
    }
  },
  methods: {
    changeFilterMonth(action) {
      this.$emit("changeFilterMonth", action);
    },
    renderDatesOnCalendar(dates) {
      if (!dates) {
        return;
      }
      this.datePickerAttributes[0].dates = dates.map((date) => {
        const year = date.substring(0, 4);
        let month = date.substring(5, 7);
        month = Number(month) - 1;
        const day = date.substring(8, 10);
        return new Date(year, month, day);
      });
    },
    filterCalendar() {
      this.loadingCalendar = true;
      http
        .get(
          `template/dates?type=${this.selectedType}&format=${this.selectedFormat}&themeId=${this.selectedTheme}`,
          authentication.getHeaders()
        )
        .then((response) => {
          this.datePickerAttributes[0].dates = [];
          this.renderDatesOnCalendar(response?.data);
        })
        .finally(() => {
          this.loadingCalendar = false;
        });
    },
    changeStyle() {
      if (!this.selectedStyle?.id) {
        this.selectedStyle = this.styles[0];
      }
      this.$emit("changeStyle", this.selectedStyle);
      localStorage.selectedStyle = JSON.stringify(this.selectedStyle);
    },
    getStyles() {
      http
        .get(`style?take=-1`, authentication.getHeaders())
        .then((response) => {
          this.styles = response.data.list;
          let savedSelectedStyle = localStorage.selectedStyle
            ? JSON.parse(localStorage.selectedStyle)
            : {};
          if (
            localStorage.selectedStyle &&
            this.styles.map((s) => s.id).includes(savedSelectedStyle.id)
          ) {
            this.selectedStyle = savedSelectedStyle;
          } else {
            this.selectedStyle = this.styles[0];
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingPage = false;
        });
    },
    getThemes() {
      http
        .get("theme?take=-1", authentication.getHeaders())
        .then((response) => {
          this.themes = response?.data?.list;
        });
    },
    emitFilter() {
      this.$emit("filterChanged", {
        type: this.selectedType,
        format: this.selectedFormat,
        theme: this.selectedTheme,
        date: this.formattedDate,
      });
      if (window.innerWidth < 1140) {
        this.showFilter = false;
      }
      this.changeStyle();
    },
    logout() {
      authentication.logout();
    },
    hideMenu() {
      this.showMenu = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    dateFilterContentProgramming: {
      handler: function (val) {
        this.dateContentProgramming = val;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  position: relative;
  width: 100%;
  height: 100px;
  z-index: 9;
  .container {
    height: 100%;
    max-width: initial;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 720px) {
    background: linear-gradient(98deg, #1fe0b4 0.83%, #1a7dff 100%);
    .container .btn.filter {
      background: none;
      border: 1px solid #000;
      box-shadow: none;
      padding: 13px 19px;
      svg,
      span {
        color: #000;
      }
    }
  }
}

.logo {
  position: relative;
  max-height: 65%;
  margin-right: 45px;
}

.date-holder {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  .date {
    font-size: 1.3rem;
    color: #000;
    text-transform: capitalize;
  }
  svg {
    font-size: 1.1rem;
    color: #000;
    cursor: pointer;
  }
}

.filters {
  position: relative;
  width: 100%;
  display: flex;
  gap: 1.8rem;
  justify-content: flex-start;
  align-items: center;
}

.input-item {
  max-width: 180px;
}

.date-input-holder {
  position: relative;
  width: 100%;
  max-width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  span {
    position: relative;
    width: 100%;
    margin-bottom: 6px;
  }
  input,
  select {
    position: relative;
    width: 100%;
    max-width: 180px;
    height: 44px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    font-size: 1.1em;
    padding: 5px 12px !important;
    color: var(--primary);
    &:hover,
    &:focus {
      border-color: var(--primary);
    }
    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(117, 73, 32, 0.35);
    }
    &:disabled {
      filter: saturate(0);
      color: transparent;
    }
  }
  select {
    option {
      font-size: 1.15em;
    }
  }
  .loading2 {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 30%;
  }
}

.palette-colors {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: -30px;
  .color-palette-item {
    position: relative;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    left: calc(-7px * var(--i));
    border: 2px solid #fff;
  }
}

.menu-holder {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  svg {
    font-size: 1.6em;
  }
  @media screen and (min-width: 1140px) {
    margin-top: 28px;
  }
}

nav {
  position: absolute;
  top: 42px;
  right: 36px;
  width: 200px;
  padding: 15px;
  border-radius: var(--default-border-radius);
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);

  ul li {
    position: relative;
    width: 100%;
    height: 44px;

    &:not(:last-child) {
      border-bottom: 1px solid #00000015;
    }

    a {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-size: 1.2em;
        margin-left: 15px !important;
      }

      svg {
        font-size: 1.3em !important;
      }
    }
  }
}

@media only screen and (max-width: 1139px) {
  .logo {
    margin-right: initial;
    max-height: 55%;
  }
  .menu-holder {
    margin-left: initial;
  }
  header .container {
    justify-content: space-between;
  }
  .filters {
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.75rem 1rem;
    background-color: #fff;
    padding: 1.4em;
    z-index: 9;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    .filter-item {
      margin: 15px 0 0 0;
    }
  }
}

.mt-35 {
  margin-top: 29px;
  padding: 12px 22px;
}

.style-colors {
  position: relative;
  width: 20px;
  height: 20px;
}
</style>
