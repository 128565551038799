<template>
	<li>
		<div class="holder">
			<img
				:class="{ active: language == 'br' }"
				@click="setLanguage('br')"
				src="../assets/images/flag-br.png"
				alt="bandeira do Brasil"
			/>
			<img
				:class="{ active: language == 'es' }"
				@click="setLanguage('es')"
				src="../assets/images/flag-es.png"
				alt="bandeira da Espanha"
			/>
		</div>
	</li>
</template>
<script>
	import languageUtil from "../util/languageUtil";

	export default {
		data() {
			return {
				language: languageUtil.activeLanguage(),
			};
		},
		created() {
			this.$translate.setLang(this.language);
		},
		methods: {
			setLanguage(lang) {
				languageUtil.setLanguage(lang);
				this.language = languageUtil.activeLanguage();
				location.reload();
			},
		},
	};
</script>
<style lang="scss" scoped>
	.holder {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		img {
			position: relative;
			width: 23%;
			filter: saturate(0);
			opacity: 0.5;
			cursor: pointer;
			&.active {
				filter: none;
				opacity: 1;
			}
		}
	}
</style>
