<template>
  <div class="all-login">
    <div class="container">
      <div class="languages-holder">
        <div class="title">{{ t("Idiomas") }}:</div>
        <img
          :class="{ active: language == 'br' }"
          @click="setLanguage('br')"
          src="../assets/images/flag-br.png"
          alt="bandeira do Brasil"
        />
        <img
          :class="{ active: language == 'es' }"
          @click="setLanguage('es')"
          src="../assets/images/flag-es.png"
          alt="bandeira da Espanha"
        />
      </div>

      <div class="wrapper">
        <img
          src="../assets/images/logo.png"
          alt="My Social Media"
          class="logo animated zoomIn"
          style="animation-delay: 300ms"
        />

        <transition enter-active-class="animated zoomIn">
          <div class="login-holder" v-if="showLoginOptions">
            <div
              class="input-item icon animated fadeInLeft"
              style="animation-delay: 650ms"
            >
              <button @click="showLoginOptions = false" class="btn email-btn">
                <img class="btn-logo" src="../assets/images/icon-email.png" />
                <p>{{ t("CONTINUAR COM E-MAIL") }}</p>
              </button>

              <button @click="appleLogin()" class="btn mt-2 apple-btn">
                <img class="btn-logo" src="../assets/images/apple-logo.png" />
                <p v-show="!loading">{{ t("CONTINUAR COM A APPLE") }}</p>
                <div v-show="loading" class="loading-spinner"></div>
              </button>
            </div>
          </div>
        </transition>

        <transition enter-active-class="animated zoomIn">
          <div class="login-holder" v-if="!showLoginOptions">
            <p
              class="subtitle registered-user animated fadeInDown"
              style="animation-delay: 500ms"
            >
              {{ t("Já tenho cadastro") }}
            </p>
            <div
              class="input-item icon animated fadeInLeft"
              style="animation-delay: 650ms"
            >
              <span>E-mail</span>
              <font-awesome-icon :icon="['fas', 'envelope']" />
              <input
                @keypress.enter="login()"
                v-model="loginForm.email"
                type="email"
              />
            </div>

            <div
              class="input-item icon animated fadeInRight"
              style="animation-delay: 850ms"
            >
              <span>{{ t("Senha") }}</span>
              <font-awesome-icon :icon="['fas', 'key']" />
              <input
                @keypress.enter="login()"
                v-model="loginForm.password"
                type="password"
              />
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="warning mt-2"
                style="position: relative; display: block"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <transition enter-active-class="animated fadeIn">
              <router-link
                to="/recuperar-senha"
                v-show="warningMessage != ''"
                class="forgot-password mt-1"
                style="
                  position: relative;
                  display: block;
                  font-weight: bold;
                  text-align: center;
                "
              >
                {{ t("Esqueceu sua senha?") }}
              </router-link>
            </transition>

            <button
              @click="login()"
              class="btn mt-2 desktop4 mx-auto mobile8 animated flipInX"
              style="animation-delay: 1050ms"
            >
              <span v-show="!loading">{{ t("Entrar") }}</span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>
        </transition>

        <div
          class="register-holder animated fadeInUp"
          style="animation-delay: 1200ms"
        >
          <p class="subtitle color-green1" v-if="!showLoginOptions && !isIos">
            {{ t("Ainda não tem cadastro?") }}
          </p>
          <router-link
            to="/cadastro"
            style="width: 100%"
            v-if="!showLoginOptions && !isIos"
          >
            <button class="btn border signup-btn mt-2 mx-auto mobile8">
              <span>{{ t("Cadastrar") }}</span>
            </button>
          </router-link>
          <transition enter-active-class="animated fadeInUp">
            <button
              class="btn border mt-2 mx-auto mobile8"
              v-if="!showLoginOptions && isIos"
              @click="showLoginOptions = true"
            >
              <span>{{ t("Voltar") }}</span>
            </button>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http";
import validators from "../util/validators";
import authentication from "../util/authentication";
import languageUtil from "../util/languageUtil";
import platform from "../util/platform";

export default {
  name: "Login",
  data() {
    return {
      warningMessage: "",
      loading: false,
      loginForm: {
        email: "",
        password: "",
      },
      language: languageUtil.activeLanguage(),
      retryLogin: false,
      retryLoginObj: {},
      showLoginOptions: platform.isIos(),
    };
  },
  created() {
    this.$translate.setLang(this.language);
    if (authentication.isLoggedIn()) {
      this.$router.push("/home");
    }
    if (localStorage.retryL) {
      this.retryLogin = true;
      let user = JSON.parse(localStorage.retryL);
      this.retryLoginObj.email = atob(user.e);
      this.retryLoginObj.password = atob(user.p);
      this.retryLoginObj.userType = user.userType;
      this.autoLogin();
    }
  },
  computed: {
    isIos() {
      return platform.isIos();
    },
  },
  methods: {
    setLanguage(lang) {
      languageUtil.setLanguage(lang);
      this.language = languageUtil.activeLanguage();
      location.reload();
    },
    autoLogin() {
      authentication
        .login(this.retryLoginObj)
        .then((response) => {
          if (response) {
            this.$router.push("/home");
          } else {
            this.warningMessage =
              this.$translate.locale["Usuário e/ou senha incorreto(s)"];
            localStorage.removeItem("retryL");
          }
        })
        .catch(() => {
          localStorage.removeItem("retryL");
          this.warningMessage =
            this.$translate.locale[
              "Algo deu errado. Por favor, tente novamente em alguns instantes."
            ];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    login() {
      if (!validators.validateEmail(this.loginForm.email)) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, verifique se o e-mail digitado está correto."
          ];
        return;
      }
      if (this.loginForm.password == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe sua senha"];
        return;
      }

      this.warningMessage = "";
      this.loading = true;

      authentication
        .login(this.loginForm)
        .then((response) => {
          if (response) {
            localStorage.selectedStyle = null;
            let user = {
              e: btoa(this.loginForm.email),
              p: btoa(this.loginForm.password),
              userType: "DoIt",
            };
            localStorage.retryL = JSON.stringify(user);
            this.$router.push("/home");
          } else {
            this.warningMessage =
              this.$translate.locale["Usuário e/ou senha incorreto(s)"];
          }
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage =
            this.$translate.locale[
              "Algo deu errado. Por favor, tente novamente em alguns instantes."
            ];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    appleLogin() {
      if (platform.isIos()) {
        var vm = this;
        this.loading = true;
        window.cordova.plugins.SignInWithApple.signin(
          { requestedScopes: [0, 1] },
          function (succ) {
            authentication
              .login({
                email: succ.email,
                password: succ.user,
                userType: "Apple",
              })
              .then((response) => {
                if (response) {
                  let user = {
                    e: btoa(succ.email),
                    p: btoa(succ.user),
                    userType: "Apple",
                  };
                  localStorage.retryL = JSON.stringify(user);
                  vm.$router.push("home");
                } else {
                  vm.registerAppleUser(succ.email, succ.user);
                }
              });
          },
          function () {
            this.loading = false;
            this.warningMessage =
              this.$translate.locale[
                "Algo deu errado. Por favor, tente novamente em alguns instantes."
              ];
          }
        );
      } else {
        this.warningMessage =
          this.$translate.locale[
            "Algo deu errado. Por favor, tente novamente em alguns instantes."
          ];
      }
    },
    registerAppleUser(email, appleId) {
      var userLanguage = "Português";
      if (this.language === "es") {
        userLanguage = "Espanhol";
      }
      var user = {
        name: "",
        professionalName: "",
        email: email,
        phone: "",
        cpf: "",
        zipcode: "",
        state: "",
        city: "",
        neighborhood: "",
        address: "",
        number: "",
        complement: "",
        birthday: new Date(),
        gender: "",
        language: userLanguage,
        career: "",
        password: "",
        authorizationCode: appleId,
      };
      http
        .post("user/apple", user)
        .then((response) => {
          localStorage.user = JSON.stringify(response.data);
          this.$router.push("/assinatura/apple");
        })
        .catch((err) => {
          this.warningMessage = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.apple-btn,
.email-btn {
  width: 100%;
}
.apple-btn {
  background: initial;
}
.apple-btn p {
  color: #fff;
}
.apple-btn p,
.email-btn p {
  font-family: fontBold;
  text-align: center;
  line-height: 26px;
  width: 250px;
  margin-left: 10px;
}
.btn-logo {
  position: absolute;
  width: 25px;
  left: 15px;
}
.all-login {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: var(--secondary);
  background-image: url("../assets/images/bg1.jpg");
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;
}
.logo {
  position: relative;
  display: block;
  max-width: 200px;
  margin: 20px auto 0 auto;
}

.signup-btn {
  border-color: var(--green1);
  span {
    color: var(--green1);
  }
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.login-holder {
  width: 100%;
  .input-item span {
    color: #fff;
  }
  .subtitle.registered-user {
    text-align: center;
    margin-bottom: 25px;
  }
}

.warning,
.forgot-password {
  position: relative;
  font-size: 1.15em;
  color: #e1cd5c;
  text-align: center;
}

.forgot-password {
  letter-spacing: 1px;
  color: #fff;
}

.register-holder {
  position: relative;
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .subtitle {
    text-align: center;
  }
}

.languages-holder {
  position: absolute;
  top: 2.5rem;
  right: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  .title {
    position: relative;
    font-size: 1.2em;
    font-family: fontRegular;
    width: 100%;
    color: #fff;
    text-align: right;
  }
  img {
    max-width: 36px;
    filter: saturate(0);
    opacity: 0.7;
    cursor: pointer;
    image-rendering: -webkit-optimize-contrast;
    transition: filter 0.2s linear, opacity 0.2s linear;
    &.active,
    &:hover {
      filter: saturate(1);
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 720px) {
  .languages-holder {
    top: 0.8rem;
    right: -0.4rem;
    gap: 6px;
    .title {
      font-size: 1.4em;
    }
    img {
      width: 28px;
    }
  }
}
</style>
