export default {
  isMobile() {
    return (this.isAndroid() || this.isIos());
  },
  isAndroid() {
    try {
      platform = device.platform;
      return platform === "Android" && !isIosForcedForDev();
    } catch {
      return localStorage.platform === "Android" && !isIosForcedForDev();
    }
  },
  isIos() {
    try {
      platform = device.platform;
      return platform === "iOS" || isIosForcedForDev();
    } catch {
      return localStorage.platform === "iOS" || isIosForcedForDev();
    }
  }
}

function isIosForcedForDev() {
  return process.env.VUE_APP_FORCE_IOS_MODE === "true"
}