<template>
  <div class="all">
    <DailyInsight
      :show="showDailyInsight"
      :tips="tips"
      @close="closeDailyInsight"
    />

    <Navbar class="tablet-hidden mobile-hidden" />
    <div class="page">
      <HeaderFilter
        @filterChanged="filterPosts"
        @changeStyle="changeImageStyle"
      />

      <div class="home container">
        <div class="flex flex-between">
          <div
            v-show="!loadingPosts && !loading"
            class="title mt-2 mb-2-desktop mb-1-mobile mb-1-tablet"
          >
            Templates
          </div>
          <div
            @click="showDailyInsight = true"
            class="btn icon bg-purple"
            style="border-color: var(--purple)"
          >
            <font-awesome-icon :icon="['fas', 'lightbulb']" />
            <span>Insight do dia</span>
          </div>
        </div>
        <div class="gallery-holder">
          <transition v-show="!loadingPosts && !loading && posts.length > 0">
            <div
              id="gallery"
              v-masonry="'gallery'"
              :gutter="10"
              transition-duration="0.3s"
              item-selector=".block-item"
            >
              <div
                v-masonry-tile
                class="block-item"
                :class="{ stories: item.type == 'stories' }"
                v-for="item in posts"
                :key="item.id"
              >
                <span class="type">{{ item.type }}</span>
                <router-link
                  :to="`/conteudo/${item.id}`"
                  class="img-holder"
                  :style="`background-image:url(${item.image});`"
                ></router-link>
              </div>
            </div>
          </transition>
        </div>

        <div v-show="loading || loadingPosts" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <span
          v-show="!loadingPosts && !loading && posts.length < 1"
          class="warning text-center-mobile mt-1"
          style="display: block"
        >
          {{ t("Não há conteúdo referente a esta data.") }}
        </span>

        <div class="flex flex-center">
          <div
            v-show="page < totalPages"
            @click="getMore"
            class="btn mt-2 desktop4 mx-auto"
          >
            <span v-show="!loadingMore">{{ t("Ver mais") }}</span>
            <div v-show="loadingMore" class="loading2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import authentication from "../util/authentication";
import Navbar from "../components/Navbar.vue";
import HeaderFilter from "../components/HeaderFilter.vue";
import DailyInsight from "../components/InsightOfTheDay.vue";
export default {
  components: {
    Navbar,
    HeaderFilter,
    DailyInsight,
  },
  data() {
    return {
      loading: true,
      loadingPosts: true,
      loadingMore: false,
      showDailyInsight: false,
      posts: [],
      page: 1,
      take: 10,
      totalPages: 1,
      tips: [],
      filter: {
        type: "",
        format: "",
        style: "",
        theme: "",
        date: this.$moment(Date()).format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    isAppleUser() {
      return authentication.getUser().info.userType === "Apple";
    },
    isUserPremium() {
      return authentication.getUser().info.premium;
    },
    userPlanLink() {
      return this.isAppleUser ? "/assinatura/apple" : "/meu-plano";
    },
  },
  created() {
    this.verifySubscription();
  },
  methods: {
    closeDailyInsight() {
      this.showDailyInsight = false;
    },
    verifySubscription() {
      if (this.isAppleUser) {
        var receipt = state?.subscription?.transaction?.appStoreReceipt || "";
        this.getSubscription(receipt);
      } else {
        this.getSubscription("");
      }
    },
    getSubscription(receipt) {
      http
        .post("user/subscription", { receipt }, authentication.getHeaders())
        .then((response) => {
          var subscription = response.data;
          // if (!subscription.planDetails.isValid && !this.isUserPremium) {
          //   this.$router.push(this.userPlanLink);
          // }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.getDailyTip();
          this.getPosts();
        });
    },
    getDailyTip() {
      http
        .get("/tip?isDailyTip=true", authentication.getHeaders())
        .then((response) => {
          this.tips = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPosts() {
      this.loadingPosts = true;
      http
        .get(
          `template?take=${this.take}&page=${this.page}&type=${this.filter.type}&format=${this.filter.format}&date=${this.filter.date}&themeId=${this.filter.theme}&orderBy=timestamp`,
          authentication.getHeaders()
        )
        .then((response) => {
          this.posts = [
            ...this.posts,
            ...this.addImageField(response.data.list),
          ];
          this.setImageByStyle(localStorage.selectedStyle);
          this.totalPages = response.data.pages;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$redrawVueMasonry("gallery");
          this.loadingPosts = false;
          this.loadingMore = false;
          let selectedStyle = JSON.parse(localStorage.selectedStyle);
          if (selectedStyle) this.changeImageStyle(selectedStyle);
        });
    },
    filterPosts(filterObj) {
      this.page = 1;
      this.filter = { ...this.filter, ...filterObj };
      this.posts = [];
      this.getPosts();
    },
    addImageField(list) {
      return list.map((obj) => {
        obj.image = "";
        return obj;
      });
    },
    changeImageStyle(selectedStyle) {
      localStorage.selectedStyle = JSON.stringify(selectedStyle);
      this.setImageByStyle(selectedStyle.id);
    },
    setImageByStyle(selectedStyleId) {
      for (let i = 0; i < this.posts.length; i++) {
        let styleObj = this.posts[i].templateStyle.find(
          (obj) => obj.styleId === selectedStyleId
        );

        if (styleObj) {
          this.posts[i].image = styleObj.image;
        }
      }
    },
    getMore() {
      this.loadingMore = true;
      this.page = this.page + 1;
      this.getPosts();
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  color: var(--secondary) !important;
}

.page {
  position: relative;
  padding-bottom: 45px;
}

.home.container {
  width: 90%;
  max-width: initial;
  @media screen and (max-width: 720px) {
    position: relative;
    width: 100vw;
    .flex.flex-between {
      flex-direction: column-reverse;
      .btn.icon.bg-purple {
        width: 100%;
        border-radius: 0 0 5px 5px;
        border-color: #000 !important;
        background-color: #000 !important;
      }
    }
  }
}

.gallery-holder {
  position: relative;
  width: 100%;
  margin: 1rem auto 0 auto;
  @media screen and (max-width: 720px) {
    width: 95%;
  }
}

.loading-center {
  left: 50%;
  margin-left: -8px;
  position: relative;
}

.block-item {
  position: relative;
  width: calc(25% - 10px);
  margin-bottom: 10px;
  @media screen AND (max-width: 1021px) {
    min-width: initial;
    width: calc(33.333% - 10px);
  }
  @media screen AND (max-width: 720px) {
    min-width: initial;
    width: calc(50% - 10px);
  }
  @media screen AND (max-width: 590px) {
    min-width: initial;
    width: calc(50% - 10px);
  }
  .type {
    font-size: 1.1em;
    margin-left: 5px;
  }
  .img-holder {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    background-color: #333;
    border-radius: var(--default-border-radius);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.stories {
    .img-holder {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 177.777%;
      h1 {
        color: #fff;
      }
    }
  }
}

.divider {
  height: 1.75px;
  margin: 0 auto;
  background-color: #eee;
}

.videos-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 1.5em;
  iframe {
    position: relative;
    width: 100%;
    max-width: 600px;
    aspect-ratio: 16/9;
  }
}
</style>
