import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import Home from "../views/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    alias: ["/"],
    component: Login,
  },
  {
    path: "/cadastro",
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/programacao-conteudo",
    meta: {
      title: "Programação de conteúdo",
    },
    component: () => import("../views/ContentCalendar.vue"),
  },
  {
    path: "/apoio-extra",
    meta: {
      title: "Apoio Extra",
    },
    component: () => import("../views/Tips.vue"),
  },
  {
    path: "/conteudo/:id",
    meta: {
      title: "Conteúdo Detalhes",
    },
    component: () => import("../views/TemplateDetails.vue"),
  },
  {
    path: "/meu-selo",
    meta: {
      title: "Meu Selo",
    },
    component: () => import("../views/UserPicture.vue"),
  },
  {
    path: "/meus-dados",
    meta: {
      title: "Meus Dados",
    },
    component: () => import("../views/UserProfile.vue"),
  },
  {
    path: "/recuperar-senha",
    meta: {
      title: "Recuperar Senha",
    },
    component: () => import("../views/PasswordRecovery.vue"),
  },
  {
    path: "/meu-plano",
    meta: {
      title: "Detalhes do Plano",
    },
    component: () => import("../views/PlanDetails.vue"),
  },
  {
    path: "/assinatura/apple",
    meta: {
      title: "",
    },
    component: () => import("../views/AppleSubscription.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (from.path != to.path) {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
