import axios from "axios";
import router from "@/router";

const endpoint =
  process.env.NODE_ENV === "development"
    ? "https://api.mysm.com.br/api/"
    : "https://api.mysm.com.br/api/";

const http = axios.create({
  baseURL: endpoint,
});

http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("user");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default http;
