<template>
  <div class="navbar">
    <div class="shell">
      <img
        class="logo"
        src="../assets/images/logo.png"
        alt="My Social Media - Logo"
      />
      <ul>
        <li>
          <router-link to="/home">
            <font-awesome-icon :icon="['fas', 'home']" />
            <span>{{ t("Início") }}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/programacao-conteudo">
            <font-awesome-icon :icon="['fas', 'calendar-alt']" />
            <span>{{ t("Programação de conteúdo") }}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/apoio-extra">
            <font-awesome-icon :icon="['fas', 'lightbulb']" />
            <span>{{ t("Apoio extra") }}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/meu-selo">
            <font-awesome-icon :icon="['fas', 'camera']" />
            <span>{{ t("Meu selo") }}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/meus-dados">
            <font-awesome-icon :icon="['fas', 'user']" />
            <span>{{ t("Meus dados") }}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/meu-plano">
            <font-awesome-icon :icon="['fas', 'id-badge']" />
            <span>{{ t("Meu plano") }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  position: relative;
  width: 220px;
  height: 100vh;
  background: linear-gradient(225deg, #000 0%, #26063e 100%);
  .shell {
    position: fixed;
    width: 220px;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 12px;
    background: linear-gradient(225deg, #000 0%, #26063e 100%);
  }
  .logo {
    position: relative;
    display: block;
    margin: 35px auto;
    max-width: 70%;
  }
  ul {
    position: relative;
    li {
      position: relative;
      width: 100%;
      list-style: none;
      a {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 14px 20px;
        border-radius: var(--default-border-radius);
        margin: 15px 0 0 0;
        transition: background-color 0.2s ease;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        span {
          font-size: 1.1em;
          color: #f0f0f0;
        }
        svg {
          font-size: 1.4em;
          margin-right: 15px;
          color: #f0f0f0;
        }
        &.router-link-exact-active {
          background-color: var(--primary);
          svg,
          span {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
