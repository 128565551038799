<template>
  <div class="insight-holder">
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="show" @click="close" class="bg-cover"></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div v-show="show" class="modal-holder">
        <div class="flex flex-between">
          <div class="title">Insights do dia</div>
          <div @click="close" class="close">X</div>
        </div>

        <iframe
          v-if="tips[0]"
          :key="tips[0].id"
          :src="`https://www.youtube.com/embed/${videoLink}`"
          title="YouTube video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    videoLink() {
      return this.show && this.tips[0]?.video ? this.tips[0].video : "";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.insight-holder {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.modal-holder {
  position: absolute;
  width: 90%;
  max-width: 600px;
  padding: 2rem 2rem 2.6rem 2rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  .close {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.06);
    display: grid;
    place-items: center;
    font-family: fontMedium;
    cursor: pointer;
  }
  iframe {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 10px;
    margin-top: 2rem;
  }
}
</style>
