<template>
  <div id="app">
    <div class="font-preload">
      <p>1</p>
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import languages from "@/util/languages";
export default {
  name: "App",
  locales: languages,
  mounted() {
    document.addEventListener("keydown", (event) => {
      const key = event.key;
      // switch (key) {
      //   case "F12":
      //     event.preventDefault();
      //     break;
      // }
    });
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  },
};
</script>
<style lang="scss">
:root {
  --primary: #1a7dff;
  --secondary: #060a16;
  --yellow1: #ffb800;
  --red1: hsl(0, 87%, 47%);
  --red2: hsl(349, 92%, 53%);
  --green1: #24e738;
  --orange1: #fd9433;
  --light-blue: #82a2f4;
  --purple: #9508ff;
  --dark1: #070707;
  --dark2: #1a1a1a;
  --dark3: #212121;
  --dark4: #373737;
  --default-border-radius: 6px;
}

.color-primary {
  color: var(--primary) !important;
}
.color-secondary {
  color: var(--secondary) !important;
}
.color-purple {
  color: var(--purple) !important;
}
.color-green1 {
  color: var(--green1) !important;
}
.bg-primary {
  background-color: var(--primary) !important;
}
.bg-secondary {
  background-color: var(--secondary) !important;
}
.desaturate {
  filter: saturate(0) !important;
}
.bg-red {
  background-color: var(--red1) !important;
}
.bg-green {
  background-color: var(--green1) !important;
}
.bg-purple {
  background-color: var(--purple) !important;
}

*:not(path) {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 13px;
  font-family: fontRegular;
  color: var(--dark4);
  box-sizing: border-box;
  list-style: none;
}

html,
body {
  width: 100vw;
  min-height: 100vh;
  scroll-behavior: "smooth";
}

@font-face {
  font-family: "fontRegular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontLight";
  src: url("./assets/fonts/Roboto-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontMedium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontBold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontBlack";
  src: url("./assets/fonts/Roboto-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "maryKayScript";
  src: url("./assets/fonts/MaryKayScript-Bold.ttf");
  font-display: swap;
}

.bg-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.title,
.subtitle {
  display: inline-block;
  font-family: fontMedium;
  font-size: 2rem;
  color: var(--primary);
  background-image: linear-gradient(90.09deg, #1782fd, #1f9f2b 85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.6em;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.loading2 {
  animation: rotate-forever 0.75s infinite linear;
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary);
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading2.black {
  border: 2px solid #000;
  border-right-color: transparent;
}

.loading2.white {
  border: 2px solid #fff;
  border-right-color: transparent;
}

.btn {
  position: relative;
  padding: 16px 24px;
  border-radius: var(--default-border-radius);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  svg {
    font-size: 1.3em;
    color: #fff;
  }
  span {
    font-size: 1.15em;
    color: #fff;
  }
  &.border {
    background: transparent;
    svg {
      color: var(--primary);
    }
    span {
      color: var(--primary);
    }
  }
}

.all {
  position: relative;
  width: 100vw;
  display: grid;
  grid-template-columns: 220px 1fr;
  @media screen AND (max-width: 1139px) {
    grid-template-columns: 1fr;
  }
}

.loading-spinner {
  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  -moz-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 20px;
  width: 20px;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.full-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  span {
    position: relative;
    width: 100%;
    margin-bottom: 6px;
  }
  svg {
    position: absolute;
    display: block;
    font-size: 1.4em;
    z-index: 1;
    left: 10px;
    top: 36px;
    color: var(--secondary);
  }
  input,
  select {
    position: relative;
    width: 100%;
    height: 44px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    font-size: 1.1em;
    padding: 5px 6px;
    color: var(--primary);
    &:hover,
    &:focus {
      border-color: var(--primary);
    }
    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(117, 73, 32, 0.35);
    }
  }
  select {
    option {
      font-size: 1.15em;
    }
  }
  &.icon {
    input,
    select {
      padding-left: 38px;
    }
  }
}

@media screen and (max-width: 1139px) {
  input,
  select {
    appearance: none;
    padding-left: 8px;
  }
}

.signup-buttons-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  .btn {
    box-shadow: none;
    &.prev {
      border-color: #888;
      span {
        color: #888;
      }
    }
    &.next {
      margin-left: auto;
      border-color: var(--secondary);
      span {
        color: var(--secondary);
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.font-preload {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  p:nth-child(1) {
    font-family: maryKayScript;
  }
}

.warning {
  font-family: fontRegular;
  font-size: 1.1rem;
  line-height: 1.5rem;
  &.text-center {
    text-align: center;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label,
  span {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--primary);
    width: 100%;
    display: block;
    user-select: none;
  }
  input,
  &.select {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    padding: 5px 15px;
    background: none;
    color: var(--primary);
    &::-webkit-input-placeholder {
      font-family: fontLight;
      font-size: 1rem;
    }

    &:hover,
    &:focus {
      border-color: var(--primary);
    }

    &:focus {
      border-color: var(--primary);
      box-shadow: 0px 10px 20px -13px rgba(117, 73, 32, 0.35);
    }
  }
  &.select {
    background-size: 12px;
    background-position: 90% center;
    background-repeat: no-repeat;
    padding-right: 30px;
  }
}
</style>
