export default {
  br: {
    "Não há conteúdo referente a esta data.":
      "Não há conteúdo referente a esta data.",
    "Não há apoio extra referente a esta data.":
      "Não há apoio extra referente a esta data.",
    "Não há dica referente a essa data.": "Não há dica referente a essa data.",
    "Apoio extra": "Apoio extra",
    "Por favor, informe o seu Instagram": "Por favor, informe o seu Instagram",
    "Ver mais": "Ver mais",
    Idiomas: "Idiomas",
    "Já tenho cadastro": "Já tenho cadastro",
    Senha: "Senha",
    "Esqueceu sua senha?": "Esqueceu sua senha?",
    Entrar: "Entrar",
    "Ainda não tem cadastro?": "Ainda não tem cadastro?",
    Cadastrar: "Cadastrar",
    "Por favor, verifique se o e-mail digitado está correto.":
      "Por favor, verifique se o e-mail digitado está correto.",
    "Por favor, informe sua senha": "Por favor, informe sua senha",
    "Usuário e/ou senha incorreto(s)": "Usuário e/ou senha incorreto(s)",
    "Algo deu errado. Por favor, tente novamente em alguns instantes.":
      "Algo deu errado. Por favor, tente novamente em alguns instantes.",
    "Informe o seu e-mail": "Informe o seu e-mail",
    Avançar: "Avançar",
    Voltar: "Voltar",
    "Enviamos um código para o e-mail": "Enviamos um código para o e-mail",
    "Por favor, insira o código recebido no campo abaixo.":
      "Por favor, insira o código recebido no campo abaixo.",
    Código: "Código",
    AVANÇAR: "AVANÇAR",
    "Nova senha": "Nova senha",
    "Digite a sua nova senha": "Digite a sua nova senha",
    "Confirmar senha": "Confirmar senha",
    "Confirme a sua senha": "Confirme a sua senha",
    "Senha alterada com sucesso.": "Senha alterada com sucesso.",
    "Faça login": "Faça login",
    "agora mesmo.": "agora mesmo.",
    "Trocar senha": "Trocar senha",
    "Por favor, informe um e-mail válido.":
      "Por favor, informe um e-mail válido.",
    "Usuário não encontrado em nosso sistema.":
      "Usuário não encontrado em nosso sistema.",
    "Parece que o código informado está incorreto. Verifique e tente novamente.":
      "Parece que o código informado está incorreto. Verifique e tente novamente.",
    "Por favor, informe sua nova senha": "Por favor, informe sua nova senha",
    "Por favor, confirme a sua nova senha":
      "Por favor, confirme a sua nova senha",
    "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente":
      "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente",
    "Já possui cadastro?": "Já possui cadastro?",
    "Faça login aqui": "Faça login aqui",
    Cadastro: "Cadastro",
    Perfil: "Perfil",
    Plano: "Plano",
    Pagto: "Pagto",
    Baixar: "Baixar",
    "Copiar legenda": "Copiar legenda",
    "Você só pode trocar sua foto uma vez a cada 7 dias.":
      "Você só pode trocar sua foto uma vez a cada 7 dias.",
    "Última troca em": "Última troca em",
    "Escolher nova foto": "Escolher nova foto",
    "Nome profissional / artístico / comercial":
      "Nome profissional / artístico / comercial",
    "Salvar alterações": "Salvar alterações",
    "Por favor, selecione uma nova foto": "Por favor, selecione uma nova foto",
    "Por favor, informe seu nome profissional / artístico / comercial":
      "Por favor, informe seu nome profissional / artístico / comercial",
    "Dados atualizados com sucesso!": "Dados atualizados com sucesso!",
    "A imagem só pode ser alterada a cada 30 dias":
      "A imagem só pode ser alterada a cada 30 dias",
    "Algo deu errado com o upload da imagem mobile.":
      "Algo deu errado com o upload da imagem mobile.",
    "Informações pessoais": "Informações pessoais",
    "Nome completo": "Nome completo",
    "Telefone/celular": "Telefone/celular",
    Sexo: "Sexo",
    Feminino: "Feminino",
    Masculino: "Masculino",
    Profissão: "Profissão",
    "Selecione uma opção": "Selecione uma opção",
    Português: "Português",
    Espanhol: "Espanhol",
    CEP: "CEP",
    UF: "UF",
    Cidade: "Cidade",
    Bairro: "Bairro",
    Endereço: "Endereço",
    Número: "Número",
    Complemento: "Complemento",
    Atualizar: "Atualizar",
    "Senha atual": "Senha atual",
    "Confirmar nova senha": "Confirmar nova senha",
    "Alterar senha": "Alterar senha",
    "Por favor, informe a sua senha atual":
      "Por favor, informe a sua senha atual",
    "Por favor, informe a sua senha nova":
      "Por favor, informe a sua senha nova",
    "Por favor, confirme a sua senha nova":
      "Por favor, confirme a sua senha nova",
    "Sua nova senha está diferente da confirmação de senha.":
      "Sua nova senha está diferente da confirmação de senha.",
    "Senha atualizada com sucesso!": "Senha atualizada com sucesso!",
    "Por favor, informe o seu nome completo":
      "Por favor, informe o seu nome completo",
    "Por favor, informe um e-mail válido":
      "Por favor, informe um e-mail válido",
    "Por favor, informe o seu cpf corretamente":
      "Por favor, informe o seu cpf corretamente",
    "Por favor, informe sua data de nascimento":
      "Por favor, informe sua data de nascimento",
    "Por favor, informe um número de telefone ou celular":
      "Por favor, informe um número de telefone ou celular",
    "Por favor, informe o seu CEP": "Por favor, informe o seu CEP",
    "Por favor, informe o seu estado": "Por favor, informe o seu estado",
    "Por favor, informe a sua cidade": "Por favor, informe a sua cidade",
    "Por favor, informe o seu bairro": "Por favor, informe o seu bairro",
    "Por favor, informe o seu endereço": "Por favor, informe o seu endereço",
    "Por favor, informe a sua profissão": "Por favor, informe a sua profissão",
    "Por favor, informe um CEP válido": "Por favor, informe um CEP válido",
    "Cartão de crédito": "Cartão de crédito",
    Boleto: "Boleto",
    "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone pode manter o seu)":
      "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone pode manter o seu)",
    CPF: "CPF",
    "Data de nascimento": "Data de nascimento",
    "Nome impresso": "Nome impresso",
    Validade: "Validade",
    "Número do cartão": "Número do cartão",
    "Nome impresso no cartão": "Nome impresso no cartão",
    "Bandeira do cartão": "Bandeira do cartão",
    Mês: "Mês",
    Ano: "Ano",
    "Resumo das informações": "Resumo das informações",
    "Descrição do plano": "Descrição do plano",
    "Método de pagamento": "Método de pagamento",
    "Gerar boleto e cadastrar": "Gerar boleto e cadastrar",
    "Por favor, informe um cpf válido": "Por favor, informe um cpf válido",
    "Por favor, informe uma data de nascimento válida":
      "Por favor, informe uma data de nascimento válida",
    "Por favor, informe um número válido":
      "Por favor, informe um número válido",
    "Por favor, informe um UF válido": "Por favor, informe um UF válido",
    "Por favor, informe uma cidade": "Por favor, informe uma cidade",
    "Por favor, informe o bairro": "Por favor, informe o bairro",
    "Por favor, informe o seu número": "Por favor, informe o seu número",
    "Por favor, verifique o número do cartão informado":
      "Por favor, verifique o número do cartão informado",
    "Por favor, informe o nome impresso no cartão":
      "Por favor, informe o nome impresso no cartão",
    "Por favor, informe a bandeira do cartão. Aceitamos Mastercard, Visa, Amex, Diners, Elo e Hipercard":
      "Por favor, informe a bandeira do cartão. Aceitamos Mastercard, Visa, Amex, Diners, Elo e Hipercard",
    "Por favor, informe o seu sexo": "Por favor, informe o seu sexo",
    "Por favor, informe uma profissão": "Por favor, informe uma profissão",
    "Por favor, informe um idoma": "Por favor, informe um idoma",
    "Por favor, digite a sua senha": "Por favor, digite a sua senha",
    "Por favor, confirme a sua senha": "Por favor, confirme a sua senha",
    "Suas senhas são diferentes. Por favor, digite-as novamente":
      "Suas senhas são diferentes. Por favor, digite-as novamente",
    Início: "Início",
    "Programação de conteúdo": "Programação de conteúdo",
    "Meu selo": "Meu selo",
    "Meus dados": "Meus dados",
    "Meu plano": "Meu plano",
    "Termos de Uso": "Termos de Uso",
    Sair: "Sair",
    Filtros: "Filtros",
    Tipo: "Tipo",
    Todos: "Todos",
    Imagem: "Imagem",
    Vídeo: "Vídeo",
    Carrossel: "Carrossel",
    "Insight de hoje": "Insight de hoje",
    Conteúdo: "Conteúdo",
    "Conteúdo Detalhes": "Conteúdo Detalhes",
    "Esta página não existe mais ou foi removida.":
      "Esta página não existe mais ou foi removida.",
    "Clique aqui para voltar ao início": "Clique aqui para voltar ao início",
    Data: "Data",
    "Edite sua foto": "Edite sua foto",
    Pronto: "Pronto",
    Cancelar: "Cancelar",
    Idioma: "Idioma",
    "Nome no cartão": "Nome no cartão",
    Nome: "Nome",
    "Complete suas informações": "Complete suas informações",
    "Para baixar o template, por favor, certifique-se de subir uma foto e informar seu nome profissional / artístico / comercial.":
      "Para baixar o template, por favor, certifique-se de subir uma foto e informar seu nome profissional / artístico / comercial.",
    Tema: "Tema",
    "Clique em uma imagem para exibi-la": "Clique em uma imagem para exibi-la",
    "Imagem baixada!": "Imagem baixada!",
    "Vídeo baixado!": "Vídeo baixado!",
    "Texto copiado!": "Texto copiado!",
    "Finalizando cadastro": "Finalizando cadastro",
    "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente.":
      "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente.",
    "Erro no pagamento": "Erro no pagamento",
    "Você recebrá o boleto no e-mail": "Você recebrá o boleto no e-mail",
    "em até 48 horas": "em até 48 horas",
    "Recebemos seu pedido.": "Recebemos seu pedido.",
    "A confirmação do pagamento pode levar até 48 horas.":
      "A confirmação do pagamento pode levar até 48 horas.",
    "Recebemos sua transação.": "Recebemos sua transação.",
    "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente.":
      "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente.",
    "Algo deu errado com o seu cadastro. Por favor, verifique sua conexão e tente novamente em alguns instantes.":
      "Algo deu errado com o seu cadastro. Por favor, verifique sua conexão e tente novamente em alguns instantes.",
    "Erro no cadastro": "Erro no cadastro",
    "Finalizando pagamento. Por favor, não feche nem recarregue a página.":
      "Finalizando pagamento. Por favor, não feche nem recarregue a página.",
    "Cadastro realizado com sucesso.": "Cadastro realizado com sucesso.",
    "Detalhes do Plano": "Detalhes do Plano",
    "Plano contratado:": "Plano contratado:",
    "Próximo Pagamento:": "Próximo Pagamento:",
    "Acesso até:": "Acesso até:",
    "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual. Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão.":
      "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual. Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão.",
    "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail":
      "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail",
    "Cancelar assinatura": "Cancelar assinatura",
    "Ativar Assinatura": "Ativar Assinatura",
    mês: "mês",
    semestre: "semestre",
    ano: "ano",
    "Pagamento Aprovado": "Pagamento Aprovado",
    "Aguardando Pagamento": "Aguardando Pagamento",
    Cancelado: "Cancelado",
    "Meus Dados": "Meus Dados",
    "Apoio Extra": "Apoio Extra",
    "Meu Selo": "Meu Selo",
    "CONTINUAR COM E-MAIL": "CONTINUAR COM E-MAIL",
    "CONTINUAR COM A APPLE": "CONTINUAR COM A APPLE",
    "Paleta de cores": "Paleta de cores",
  },
  es: {
    "Não há conteúdo referente a esta data.":
      "No hay contenido referente a esta fecha.",
    "Não há apoio extra referente a esta data.":
      "No hay soporte adicional para esta fecha.",
    "Não há dica referente a essa data.": "No hay pista para esta fecha.",
    "Apoio extra": "soporte adicional",
    "Por favor, informe o seu Instagram": "Por favor ingrese su Instagram",
    "Ver mais": "Ver más",
    Idiomas: "Idiomas",
    "Já tenho cadastro": "Ya tengo un registro",
    Senha: "Contraseña",
    "Esqueceu sua senha?": "¿Olvidaste tu contraseña?",
    Entrar: "Entrar",
    "Ainda não tem cadastro?": "¿Aún no tienes un registro?",
    Cadastrar: "Registrarse",
    "Por favor, verifique se o e-mail digitado está correto.":
      "Por favor, verifique que el e-mail que ingresó sea correcto.",
    "Por favor, informe sua senha": "Por favor ingrese su contraseña",
    "Usuário e/ou senha incorreto(s)": "Usuario y/o contraseña incorrecto(s)",
    "Algo deu errado. Por favor, tente novamente em alguns instantes.":
      "Se produjo un error. Vuelva a intentarlo en unos momentos.",
    "Informe o seu e-mail": "Ingrese su e-mail",
    Avançar: "Adelante",
    Voltar: "Atras",
    "Enviamos um código para o e-mail": "Enviamos un código al e-mail",
    "Por favor, insira o código recebido no campo abaixo.":
      "Por favor ingrese el código recibido en el campo de abajo.",
    Código: "Código",
    AVANÇAR: "SIGUIENTE",
    "Nova senha": "Nueva contraseña",
    "Digite a sua nova senha": "Ingrese su nueva contraseña",
    "Confirmar senha": "Confirmar contraseña",
    "Confirme a sua senha": "Confirme su contraseña",
    "Senha alterada com sucesso.": "La contraseña se cambió correctamente.",
    "Faça login": "Iniciar sesión",
    "agora mesmo.": "ahora mismo.",
    "Trocar senha": "Cambiar contraseña",
    "Por favor, informe um e-mail válido.":
      "Por favor introduzca una dirección de e-mail válida.",
    "Usuário não encontrado em nosso sistema.":
      "Usuario no encontrado en nuestro sistema.",
    "Parece que o código informado está incorreto. Verifique e tente novamente.":
      "Parece que el código que ingresó es incorrecto. Verifíquelo y vuelva a intentarlo.",
    "Por favor, informe sua nova senha":
      "Por favor, introduzca su nueva contraseña",
    "Por favor, confirme a sua nova senha":
      "Por favor, confirme su nueva contraseña",
    "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente":
      "Su contraseña y la confirmación de la contraseña son diferentes. Por favor, verifique y vuelva a intentarlo",
    "Já possui cadastro?": "¿Ya tienes un registro?",
    "Faça login aqui": "Iniciar sesión aquí",
    Cadastro: "Registro",
    Perfil: "Perfil",
    Plano: "Plan",
    Pagto: "Pago",
    Baixar: "Descargar",
    "Copiar legenda": "Copiar título",
    "Você só pode trocar sua foto uma vez a cada 7 dias.":
      "Solo puedes cambiar tu foto una vez cada 7 días.",
    "Última troca em": "Último cambio en",
    "Escolher nova foto": "Elegir nueva foto",
    "Nome profissional / artístico / comercial":
      "Nombre profesional / artístico / comercial",
    "Salvar alterações": "Guardar cambios",
    "Por favor, selecione uma nova foto":
      "Por favor, seleccione una foto nueva",
    "Por favor, informe seu nome profissional / artístico / comercial":
      "Por favor, ingrese su nombre profesional / artístico / comercial",
    "Dados atualizados com sucesso!": "¡Datos actualizados correctamente!",
    "A imagem só pode ser alterada a cada 30 dias":
      "La imagen solo se puede cambiar cada 30 días",
    "Algo deu errado com o upload da imagem mobile.":
      "Se produjo un error al cargar la imagen para dispositivos móviles.",
    "Informações pessoais": "Información personal",
    "Nome completo": "Nombre completo",
    "Telefone/celular": "Teléfono/celular",
    Sexo: "Género",
    Feminino: "Femenino",
    Masculino: "Masculino",
    Profissão: "Profesión",
    "Selecione uma opção": "Seleccionar una opción",
    Português: "Portugués",
    Espanhol: "Español",
    CEP: "Código postal",
    UF: "Estado",
    Cidade: "Ciudad",
    Bairro: "Bairro",
    Endereço: "Dirección",
    Número: "Número",
    Complemento: "Complemento",
    Atualizar: "Actualizar",
    "Senha atual": "Contraseña actual",
    "Confirmar nova senha": "Confirmar nueva contraseña",
    "Alterar senha": "Cambiar contraseña",
    "Por favor, informe a sua senha atual":
      "Por favor, ingrese su contraseña actual",
    "Por favor, informe a sua senha nova":
      "Por favor, ingrese su nueva contraseña",
    "Por favor, confirme a sua senha nova":
      "Por favor, confirme su nueva contraseña",
    "Sua nova senha está diferente da confirmação de senha.":
      "Su nueva contraseña es diferente a la confirmación de contraseña.",
    "Senha atualizada com sucesso!": "¡Contraseña actualizada correctamente!",
    "Por favor, informe o seu nome completo":
      "Por favor, ingresa tu nombre completo",
    "Por favor, informe um e-mail válido":
      "Por favor, introduzca una dirección de e-mail válida",
    "Por favor, informe o seu cpf corretamente":
      "Por favor, ingrese su documento correctamente",
    "Por favor, informe sua data de nascimento":
      "Por favor, introduzca su fecha de nacimiento",
    "Por favor, informe um número de telefone ou celular":
      "Por favor, ingrese un número de teléfono o celular",
    "Por favor, informe o seu CEP": "Por favor, ingrese su código postal",
    "Por favor, informe o seu estado": "Por favor, informe su estado",
    "Por favor, informe a sua cidade": "Por favor, informe a su ciudad",
    "Por favor, informe o seu bairro": "Por favor, informe a su barrio",
    "Por favor, informe o seu endereço": "Por favor, introduzca su dirección",
    "Por favor, informe a sua profissão": "Por favor, ingrese su profesión",
    "Por favor, informe um CEP válido":
      "Por favor, ingrese un código postal válido",
    "Cartão de crédito": "Tarjeta de crédito",
    Boleto: "Boleto",
    "Se você é o titular do cartão de crédito, deixe essa opção marcada. Caso o cartão de crédito pertença a outra pessoa, desmarque essa opção e preencha os campos a seguir com os dados pessoais do titular do cartão (e-mail e telefone pode manter o seu)":
      "Si eres el titular de la tarjeta de crédito, deja esta opción marcada. Si la tarjeta de crédito pertenece a otra persona, desmarca esta opción y completa los campos a continuación con los datos personales del titular de la tarjeta (el correo electrónico y el número de teléfono pueden ser tuyos)",
    CPF: "Documento",
    "Data de nascimento": "Fecha de nacimiento",
    "Nome impresso": "Nombre impreso",
    Validade: "Validez",
    "Número do cartão": "Número de tarjeta",
    "Nome impresso no cartão": "Nombre impreso en la tarjeta",
    "Bandeira do cartão": "Bandera de tarjeta",
    Mês: "Mes",
    Ano: "Año",
    "Resumo das informações": "Resumen de información",
    "Descrição do plano": "Descripción del plan",
    "Método de pagamento": "Método de pago",
    "Gerar boleto e cadastrar": "Generar boleto y registrarse",
    "Por favor, informe um cpf válido":
      "Por favor, introduzca un documento válido",
    "Por favor, informe uma data de nascimento válida":
      "Por favor, ingresa una fecha de nacimiento valida",
    "Por favor, informe um número válido":
      "Por favor, ingrese un número valido",
    "Por favor, informe um UF válido": "Por favor, Introduzca un estado válida",
    "Por favor, informe uma cidade": "Por favor, introduzca una ciudad",
    "Por favor, informe o bairro": "Por favor, informe al barrio",
    "Por favor, informe o seu número": "Por favor, ingrese su número",
    "Por favor, verifique o número do cartão informado":
      "Por favor, verifique el número de tarjeta informado",
    "Por favor, informe o nome impresso no cartão":
      "Por favor, ingrese el nombre impreso en la tarjeta",
    "Por favor, informe a bandeira do cartão. Aceitamos Mastercard, Visa, Amex, Diners, Elo e Hipercard":
      "Por favor, informe a la bandera de la tarjeta. Aceptamos Mastercard, Visa, Amex, Diners, Elo e Hipercard",
    "Por favor, informe o seu sexo": "Por favor, ingrese su género",
    "Por favor, informe uma profissão": "Por favor, ingrese una profesión",
    "Por favor, informe um idoma": "Por favor, introduzca un idioma",
    "Por favor, digite a sua senha": "Por favor, ingrese su contraseña",
    "Por favor, confirme a sua senha": "Por favor, confirme su contraseña",
    "Suas senhas são diferentes. Por favor, digite-as novamente":
      "Sus contraseñas son diferentes. Vuelva a ingresarlas",
    Início: "Inicio",
    "Programação de conteúdo": "Programación de contenidos",
    "Meu selo": "Mi sello",
    "Meus dados": "Mis datos",
    "Meu plano": "Mi plan",
    "Termos de Uso": "Condiciones de uso",
    Sair: "Salir",
    Filtros: "Filtros",
    Tipo: "Tipo",
    Todos: "Todos",
    Imagem: "Imagen",
    Vídeo: "Video",
    Carrossel: "Carrussel",
    "Insight de hoje": "Insight de hoy",
    Conteúdo: "Contenido",
    "Conteúdo Detalhes": "Detalles del contenido",
    "Esta página não existe mais ou foi removida.":
      "Esta página ya no existe o se ha eliminado.",
    "Clique aqui para voltar ao início":
      "Haga clic aquí para volver al principio",
    Data: "Fecha",
    "Edite sua foto": "Edita tu foto",
    Pronto: "Listo",
    Cancelar: "Cancelar",
    Idioma: "Idioma",
    "Nome no cartão": "Nombre en la tarjeta",
    Nome: "Nombre",
    "Complete suas informações": "Complete su información",
    "Para baixar o template, por favor, certifique-se de subir uma foto e informar seu nome profissional / artístico / comercial.":
      "Para descargar la plantilla, asegúrese de subir una foto y proporcionar su nombre profesional / artístico / comercial.",
    Tema: "Tema",
    "Clique em uma imagem para exibi-la": "Haga clic en una imagen para verla",
    "Imagem baixada!": "¡Imagen descargada!",
    "Vídeo baixado!": "Video descargado!",
    "Texto copiado!": "¡Texto copiado!",
    "Finalizando cadastro": "Finalizando el registro",
    "Algo deu errado com o seu pagamento. Por favor, verifique todos os dados e tente novamente.":
      "Algo salió mal con su pago. Verifique todos los detalles y vuelva a intentarlo.",
    "Erro no pagamento": "Error de pago",
    "Você recebrá o boleto no e-mail":
      "Recibirá el comprobante de pago en el correo electrónico",
    "em até 48 horas": "dentro de las 48 horas",
    "Recebemos seu pedido.": "Hemos recibido su pedido.",
    "A confirmação do pagamento pode levar até 48 horas.":
      "La confirmación del pago puede tardar hasta 48 horas.",
    "Recebemos sua transação.": "Hemos recibido su transacción.",
    "Algo deu errado com a validação do seu cartão. Por favor, verifique todos os dados e tente novamente.":
      "Algo salió mal con la validación de su tarjeta. Verifique todos los datos y vuelva a intentarlo.",
    "Algo deu errado com o seu cadastro. Por favor, verifique sua conexão e tente novamente em alguns instantes.":
      "Algo salió mal con su registro. Verifique su conexión y vuelva a intentarlo en unos momentos.",
    "Erro no cadastro": "Error en el registro",
    "Finalizando pagamento. Por favor, não feche nem recarregue a página.":
      "Finalizando el pago. No cierre ni vuelva a cargar la página.",
    "Cadastro realizado com sucesso.": "Registro exitoso.",
    "Detalhes do Plano": "Detalles del Plan",
    "Plano contratado:": "Plan contratado:",
    "Próximo Pagamento:": "Siguiente pago:",
    "Acesso até:": "El acceso a los:",
    "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual. Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão.":
      "Si desea cambiar su plan o los detalles de su tarjeta de crédito, primero deberá cancelar su plan actual. Aún podrá utilizar la aplicación durante los días de su último pago. Después de estos días, podrá elegir un nuevo plan y / o cambiar los detalles de su tarjeta.",
    "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail":
      "Si ha realizado un pago hace más de 48 horas y aún no se ha procesado, consulte su correo electrónico para ver qué sucedió o contáctenos por correo electrónico",
    "Cancelar assinatura": "Darse de baja",
    "Ativar Assinatura": "Activar suscripción",
    mês: "mes",
    semestre: "semestre",
    ano: "año",
    "Pagamento Aprovado": "Pago Aprobado",
    "Aguardando Pagamento": "En espera de pago",
    Cancelado: "Cancelado",
    "Meus Dados": "Mis Datos",
    "Apoio Extra": "Soporte Adicional",
    "Meu Selo": "Mi Sello",
    "CONTINUAR COM E-MAIL": "CONTINUAR CON E-MAIL",
    "CONTINUAR COM A APPLE": "CONTINUAR CON APPLE",
    "Paleta de cores": "Paleta de colores",
  },
};
